import { MarketingStrategy } from "@shared/http/apiTypes/campaignGroup";
import compact from "lodash/compact";
import mixpanel from "mixpanel-browser";
import { EmployeeInfo, InvoiceStateValue, Status } from "shared/http/apiTypes";
import { AdType } from "shared/http/apiTypes/ad";
import { ForkedDimension } from "../http/apiTypes/forkedExperiment";
type Objects = {
  ad_label: { named: true; hasOrganization: true; hasBudget: false };
  ad: { named: true; hasOrganization: true; hasBudget: false };
  audience_event: { named: true; hasOrganization: true; hasBudget: false };
  audience: { named: true; hasOrganization: true; hasBudget: false };
  segment: { named: true; hasOrganization: true; hasBudget: false };
  billing_account: { named: false; hasOrganization: false; hasBudget: false };
  budget: { named: true; hasOrganization: true; hasBudget: true };
  campaign: { named: true; hasOrganization: true; hasBudget: true };
  changes: { named: false; hasOrganization: true; hasBudget: false };
  platform_fee_deal: { named: false; hasOrganization: true; hasBudget: false };
  spend_tier: { named: false; hasOrganization: true; hasBudget: false };
  entity: { named: true; hasOrganization: false; hasBudget: false };
  invoice_line_item: { named: false; hasOrganization: false; hasBudget: false };
  invoice: { named: false; hasOrganization: false; hasBudget: false };
  order_line_item: { named: false; hasOrganization: false; hasBudget: true };
  organization: { named: true; hasOrganization: false; hasBudget: false };
  prediction_flight: { named: true; hasOrganization: false; hasBudget: false };
  predictor_config: { named: true; hasOrganization: false; hasBudget: false };
  rule: { named: true; hasOrganization: true; hasBudget: true };
  supply_partner: { named: true; hasOrganization: false; hasBudget: false };
  publisher_blocklist: { named: true; hasOrganization: false; hasBudget: false };
  device_blocklist: { named: true; hasOrganization: false; hasBudget: false };
  ip_blocklist: { named: true; hasOrganization: false; hasBudget: false };
  user: { named: true; hasOrganization: false; hasBudget: false };
  bid_multiplier_supply_partner: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_geo: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_ad_type: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_publisher: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_placement_type: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_supply_type: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_day_of_week: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_time_of_day: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_publisher_vertical: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_partner_attributed: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_ad_label: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_user_audience_revenue_tier: { named: true; hasOrganization: true; hasBudget: true };
  bid_multiplier_user_total_revenue_tier: { named: true; hasOrganization: true; hasBudget: true };
  impact_report: { named: true; hasOrganization: true; hasBudget: false };
  experiment: { named: true; hasOrganization: true; hasBudget: false };
  forked_experiment: { named: false; hasOrganization: true; hasBudget: false };
  global_experiment: { named: true; hasOrganization: false; hasBudget: false };
  global_experiment_set: { named: true; hasOrganization: false; hasBudget: false };
  intervention: { named: false; hasOrganization: true; hasBudget: false };
  event_api_user: { named: true; hasOrganization: false; hasBudget: false };
  bid_multipliers_import_job: { named: false; hasOrganization: false; hasBudget: false };
  feed: { named: true; hasOrganization: true; hasBudget: false };
  budget_plan: { named: true; hasOrganization: true; hasBudget: true };
  creative_set: { named: true; hasOrganization: true; hasBudget: false };
  publisher_list: { named: true; hasOrganization: false; hasBudget: false };
  publisher_list_entries: { named: false; hasOrganization: false; hasBudget: false };
  spend_plans: { named: true; hasOrganization: true; hasBudget: true };
  inventory_split: { named: true; hasOrganization: true; hasBudget: true };
  command_palette: { named: true; hasOrganization: false; hasBudget: false };
  skan_campaign: { named: true; hasOrganization: true; hasBudget: false };
  video_end_card: { named: true; hasOrganization: true; hasBudget: false };
};

type MatchingElements<Prop extends "hasOrganization" | "named" | "hasBudget", Value> = {
  [Key in keyof Objects]: Objects[Key][Prop] extends Value ? Key : never;
};
type ObjectsWhere<Prop extends "hasOrganization" | "named" | "hasBudget", Value> = MatchingElements<
  Prop,
  Value
>[keyof MatchingElements<Prop, Value>];

type OrganizationSpecificObjects = ObjectsWhere<"hasOrganization", true>;
export type BudgetSpecificObjects = ObjectsWhere<"hasBudget", true>;
type NamedObjects = ObjectsWhere<"named", true>;

export type ObjectTypes = keyof Objects;

export type ScreenTypes = "Index" | "Show" | "New";

type BaseScreenEventData<ObjectType extends ObjectTypes> = {
  "Screen Type": ScreenTypes;
  "Screen Name": string;
  "Query Params"?: FlatDataStructure;
  "Object Type": ObjectType;
};

export type OrganizationSpecificEventData = {
  Organization: {
    ID: string;
    Name: string;
    Tier?: string;
  };
};

export type BudgetSpecificEventData = {
  Budget: {
    Objective?: string;
  };
};

type ShowScreenBudgetSpecificObjects = BudgetSpecificObjects;
type NewScreenBudgetSpecificObjects = Exclude<BudgetSpecificObjects, "budget" | "campaign">;

type PersistedObjectEventData<Nested extends "nested" | "normal"> = Nested extends "normal"
  ? {
      "Object ID": string;
    }
  : { "Nested Object ID": string };

type NamedObjectEventData<Nested extends "nested" | "normal"> = Nested extends "normal"
  ? {
      "Object Name": string;
    }
  : { "Nested Object Name": string };

type EventData<
  ObjectType extends ObjectTypes,
  Plurality extends "single" | "multiple",
  Persisted extends "persisted" | "new",
> = BaseScreenEventData<ObjectType> &
  (ObjectType extends OrganizationSpecificObjects ? OrganizationSpecificEventData : {}) &
  (Plurality extends "single"
    ? (ObjectType extends NamedObjects ? NamedObjectEventData<"normal"> : {}) &
        (Persisted extends "persisted" ? PersistedObjectEventData<"normal"> : {})
    : {}) & {};

export type IndexScreenEventData<ObjectType extends ObjectTypes> = EventData<ObjectType, "multiple", "persisted"> & {
  "Screen Type": "Index";
};
export type ShowScreenEventData<ObjectType extends ObjectTypes> = EventData<ObjectType, "single", "persisted"> &
  (ObjectType extends ShowScreenBudgetSpecificObjects ? BudgetSpecificEventData : {}) & {
    "Screen Type": "Show";
    "Tab Name"?: string;
  };
export type NewScreenEventData<ObjectType extends ObjectTypes> = EventData<ObjectType, "single", "new"> &
  (ObjectType extends NewScreenBudgetSpecificObjects ? BudgetSpecificEventData : {}) & {
    "Screen Type": "New";
  };

export type AnyScreenEventData<ObjectType extends ObjectTypes> =
  | IndexScreenEventData<ObjectType>
  | ShowScreenEventData<ObjectType>
  | NewScreenEventData<ObjectType>;

type ScreenEventData<ScreenType extends ScreenTypes, ObjectType extends ObjectTypes> = ScreenType extends "Show"
  ? ShowScreenEventData<ObjectType>
  : ScreenType extends "Index"
    ? IndexScreenEventData<ObjectType>
    : NewScreenEventData<ObjectType>;

const BuildData = Object.freeze({
  "Build Version": import.meta.env.VITE_CODE_VERSION!,
  "Build Time": import.meta.env.VITE_BUILD_TIME,
});

export abstract class TrackingEvent<EventData extends NestedDataStructure> {
  public readonly data: EventData;
  public abstract readonly type: string;

  public constructor(data: EventData) {
    this.data = { ...BuildData, ...data };
  }
}

export type LoadData = {
  "Load Duration Millis": number;
};

export type ViewScreenEventData<ObjectType extends ObjectTypes> = AnyScreenEventData<ObjectType> & LoadData;

export class ViewScreenEvent<ObjectType extends ObjectTypes> extends TrackingEvent<ViewScreenEventData<ObjectType>> {
  readonly type = "View Screen";
}

export type TableActionEventData<ObjectType extends ObjectTypes> =
  | IndexScreenEventData<ObjectType>
  | ShowScreenEventData<ObjectType>;

export class TableFilterToolbarButtonEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { "Filter Name": string; Source: "Toolbar Button" }
> {
  readonly type = "[Table] Filter";

  constructor(data: TableActionEventData<ObjectType> & { "Filter Name": string }) {
    super({ ...data, Source: "Toolbar Button" });
  }
}

export class TableFilterHotKeyEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { "Filter Name": string; Source: "Hot Key" }
> {
  readonly type = "[Table] Filter";

  constructor(data: TableActionEventData<ObjectType> & { "Filter Name": string }) {
    super({ ...data, Source: "Hot Key" });
  }
}

export class TableFilterDialogEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { Filters: FlatDataStructure; Source: "Dialog" }
> {
  readonly type = "[Table] Filter";

  constructor(data: TableActionEventData<ObjectType> & { Filters: FlatDataStructure }) {
    super({ ...data, Source: "Dialog" });
  }
}

export class TableLoadAllResources<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType>
> {
  readonly type = "[Table] Load All Resources";
}

export class TableLinkButtonEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { Target: string }
> {
  readonly type = "[Table] Link";
}

export class TableDownloadEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { Format: "CSV" }
> {
  readonly type = "[Table] Download";
}

export class TableColumnManagement<ObjectType extends ObjectTypes, ColumnIds extends string> extends TrackingEvent<
  { visibleColumns: ColumnIds[] } & TableActionEventData<ObjectType>
> {
  readonly type = "[Table] Column Management";
}

export class TableSearchEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableActionEventData<ObjectType> & { "Search String": string }
> {
  readonly type = "[Table] Search";
}

type TableMassActionEventData<ObjectType extends ObjectTypes> = IndexScreenEventData<ObjectType> & {
  "Action Name": string;
  "Selected Rows": number;
};

export class TableMassActionEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  TableMassActionEventData<ObjectType>
> {
  readonly type = "[Table] Mass Action";
}

type MassEditEventData<ObjectType extends ObjectTypes> = IndexScreenEventData<ObjectType> & { "Selected Rows": number };

export class MassEditOpenEvent<ObjectType extends ObjectTypes> extends TrackingEvent<MassEditEventData<ObjectType>> {
  readonly type = "[Mass Edit] Open";
}

export class MassEditAbortEvent<ObjectType extends ObjectTypes> extends TrackingEvent<MassEditEventData<ObjectType>> {
  readonly type = "[Mass Edit] Abort";
}

export class MassEditUpdateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  MassEditEventData<ObjectType> & {
    "Updated Fields": string[];
    "Field Operation": Record<string, "set" | "destroy" | "append" | "percent">;
  }
> {
  readonly type = "[Mass Edit] Update";
}

type InlineEditEventData<
  EnclosingObjectType extends ObjectTypes,
  NestedObjectType extends Exclude<ObjectTypes, "rule">,
> = (NestedObjectType extends BudgetSpecificObjects ? BudgetSpecificEventData : {}) &
  NestedObjectEventData<ScreenTypes, EnclosingObjectType, NestedObjectType, "single", "persisted"> & {
    "Edited Column": string;
    "Previous Value"?: string | number;
    "New Value"?: string | number;
    Delta?: number;
  };

export class InlineEditOpenEvent<
  EnclosingObjectType extends ObjectTypes,
  EditedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<InlineEditEventData<EnclosingObjectType, EditedObjectType>> {
  readonly type = "[Inline Edit] Open";
}

type FormEventType<ObjectType extends ObjectTypes> = AnyScreenEventData<ObjectType> & {
  "Field Name": string;
  "Field Value": any;
  "Action Type": "add" | "remove" | "select" | "deselect" | "error";
};

export class FormFieldEvent<ObjectType extends ObjectTypes> extends TrackingEvent<FormEventType<ObjectType>> {
  readonly type = `[${this.data["Screen Name"]}] Form Field Event`;
}

export class InlineEditAbortEvent<
  EnclosingObjectType extends ObjectTypes,
  EditedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<InlineEditEventData<EnclosingObjectType, EditedObjectType>> {
  readonly type = "[Inline Edit] Abort";
}

export class InlineEditUpdateEventWithPreviousValue<
  EnclosingObjectType extends ObjectTypes,
  EditedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<
  InlineEditEventData<EnclosingObjectType, EditedObjectType> & {
    "Previous Value"?: string;
    "New Value"?: string;
    Delta?: number;
  }
> {
  readonly type = "[Inline Edit] Update";
}

export class InlineEditUpdateEvent<
  EnclosingObjectType extends ObjectTypes,
  EditedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<InlineEditEventData<EnclosingObjectType, EditedObjectType>> {
  readonly type = "[Inline Edit] Update";
}

export class InlineEditCloseEvent<
  EnclosingObjectType extends ObjectTypes,
  EditedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<InlineEditEventData<EnclosingObjectType, EditedObjectType>> {
  readonly type = "[Inline Edit] Close";
}

type UploadAdsEventData<EnclosingObjectType extends ObjectTypes> = IndexScreenEventData<EnclosingObjectType> & {
  "Selected Rows": number;
};

type UploadVideoEventData<EnclosingObjectType extends ObjectTypes> = IndexScreenEventData<EnclosingObjectType>;

export class UploadAdsOpenEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  UploadAdsEventData<EnclosingObjectType>
> {
  readonly type = "[Upload Ads] Open";
}

export class UploadAdsAbortEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  UploadAdsEventData<EnclosingObjectType>
> {
  readonly type = "[Upload Ads] Abort";
}

export class UploadAdsUploadEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  UploadAdsEventData<EnclosingObjectType> & { "Ads Created": number; "Ad Type": AdType }
> {
  readonly type = "[Upload Ads] Upload";
}

export class UploadAdsResizeImageEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  UploadAdsEventData<EnclosingObjectType> & { "Original Size": string; "New Size": string }
> {
  readonly type = "[Upload Ads] Resize Image";
}

type CopyCampaignsEventData<ObjectType extends ObjectTypes> = IndexScreenEventData<ObjectType> & {
  "Selected Rows": number;
  "Copy To Different Audience": boolean;
  "Copy Ads": boolean;
  "Copy Bid Multipliers": boolean;
  "Copy Inventory Splits": boolean;
  "Change To Different Type"?: string;
};

export class UploadVideoAdsEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  UploadVideoEventData<EnclosingObjectType> & { "Action Name": string }
> {
  readonly type = "[Upload Video Ads] Action";
}

export class CopyCampaignsEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CopyCampaignsEventData<ObjectType>
> {
  readonly type = "[Copy Campaign] Copy";
}

type CopyAdsToCampaignsEventData<ObjectType extends ObjectTypes> = IndexScreenEventData<ObjectType> & {
  "Selected Rows": number;
  "Ad Ids": string[];
  "Campaign Ids": string[];
};

export class CopyAdsToCampaignsEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CopyAdsToCampaignsEventData<ObjectType>
> {
  readonly type = "[Copy Ads] Copy";
}

type CopyVariantCampaignEventData<ObjectType extends ObjectTypes> = ShowScreenEventData<ObjectType> & {
  "Selected Campaigns Count": number;
  "Campaign IDs": string[];
  "Experiment ID": string;
};

export class CopyCampaignVariantEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CopyVariantCampaignEventData<ObjectType>
> {
  readonly type = "[Object] Action";
}

export class ObjectUpdateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { Active?: boolean; Fields: string[] }
> {
  readonly type = "[Object] Update";
}

export class CampaignUpdateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & {
    Active?: boolean;
    Fields: string[];
    "Previous CPC"?: number;
    "New CPC"?: number;
    "CPC Delta"?: number;
  }
> {
  readonly type = "[Object] Update";
}

export class BudgetUpdateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & {
    Active?: boolean;
    Fields: string[];

    "Previous Objective"?: string;
    "New Objective"?: string;
  }
> {
  readonly type = "[Object] Update";
}

export class RulesUpdateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { "Changed Rules": string[] }
> {
  readonly type = "[Rules] Update";
}

export class ObjectDeleteEvent<ObjectType extends ObjectTypes> extends TrackingEvent<ShowScreenEventData<ObjectType>> {
  readonly type = "[Object] Delete";
}

export class ObjectActionEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { "Action Name": string }
> {
  readonly type = "[Object] Action";
}

export class ObjectViewTabEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { "Tab Name": string }
> {
  readonly type = "[Object] View Tab";
}

export class ToggleUseInForecastEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[Rule] Toggle Segment Selection";
}

type BaseNestedObjectEventData<ObjectType extends ObjectTypes> = {
  "Nested Object Type": ObjectType;
};

type NestedObjectEventData<
  ScreenType extends ScreenTypes,
  EnclosingObjectType extends ObjectTypes,
  NestedObjectType extends Exclude<ObjectTypes, "rule">,
  Plurality extends "single" | "multiple",
  Persisted extends "persisted" | "new",
> = ScreenEventData<ScreenType, EnclosingObjectType> &
  BaseNestedObjectEventData<NestedObjectType> &
  (Plurality extends "single"
    ? (NestedObjectType extends NamedObjects ? NamedObjectEventData<"nested"> : {}) &
        (Persisted extends "persisted" ? PersistedObjectEventData<"nested"> : {})
    : {}) & {};

export class NestedObjectCreateEvent<
  EnclosingObjectType extends ObjectTypes,
  NestedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<NestedObjectEventData<"Show", EnclosingObjectType, NestedObjectType, "single", "new">> {
  readonly type = "[Nested Object] Create";
}

export class NestedObjectUpdateEvent<
  EnclosingObjectType extends ObjectTypes,
  NestedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<
  NestedObjectEventData<"Show", EnclosingObjectType, NestedObjectType, "single", "persisted"> & { Fields: string[] }
> {
  readonly type = "[Nested Object] Update";
}

export class NestedObjectDeleteEvent<
  EnclosingObjectType extends ObjectTypes,
  NestedObjectType extends Exclude<ObjectTypes, "rule">,
> extends TrackingEvent<NestedObjectEventData<"Show", EnclosingObjectType, NestedObjectType, "single", "persisted">> {
  readonly type = "[Nested Object] Delete";
}

type NestedRuleEventData<Persisted extends "persisted" | "new"> = ScreenEventData<"Show", "campaign"> &
  BaseNestedObjectEventData<"rule"> &
  NamedObjectEventData<"nested"> &
  (Persisted extends "persisted" ? PersistedObjectEventData<"nested"> : {}) & {
    "Rule Operator": string;
    "Rule LHS Value": string | null;
    "Rule LHS Type": string | null;
    "Rule RHS Value": string | null;
    "Rule RHS Type": string | null;
  };

export class NestedRuleCreateEvent extends TrackingEvent<NestedRuleEventData<"new">> {
  readonly type = "[Nested Rule] Create";
}

export class NestedRuleUpdateEvent extends TrackingEvent<NestedRuleEventData<"persisted"> & { Fields: string[] }> {
  readonly type = "[Nested Rule] Update";
}

export class NestedRuleDeleteEvent extends TrackingEvent<NestedRuleEventData<"persisted">> {
  readonly type = "[Nested Rule] Delete";
}

export class ObjectCreateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<NewScreenEventData<ObjectType>> {
  readonly type = "[Object] Create";
}

export type CreateImpactExperimentEventData<ObjectType extends ObjectTypes> = NewScreenEventData<ObjectType> & {
  Countries: string[];
  "Device Model Split": string;
};

export class CreateImpactExperimentEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CreateImpactExperimentEventData<ObjectType>
> {
  readonly type = "[Object] Create";
  readonly "Campaign Variation Type" = "impact";
}

export type CreateExperimentEventData<ObjectType extends ObjectTypes> = NewScreenEventData<ObjectType> & {
  "Campaign Variation Type": string;
  "Experiment Variants": string[];
};

export class CreateExperimentEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CreateExperimentEventData<ObjectType>
> {
  readonly type = "[Object] Create";
}

export type CreateCreativeExperimentEventData = NewScreenEventData<"experiment"> & {
  "Creative Labels": string[];
  "Creative Campaigns": string[];
};

export class CreateCreativeExperimentEvent extends TrackingEvent<CreateCreativeExperimentEventData> {
  readonly type = "[Object] Create";
}

export type CreateForkedExperimentEventData<ObjectType extends ObjectTypes> = NewScreenEventData<ObjectType> & {
  "Original Campaign ID": string;
  "Forked Dimension": ForkedDimension;
  "Forked Values": (number | string)[];
};

export class CreateForkedExperimentEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  CreateForkedExperimentEventData<ObjectType>
> {
  readonly type = "[Object] Create";
}

type MergeForkEventData<ObjectType extends ObjectTypes> = IndexScreenEventData<ObjectType> & {
  "Original Campaign ID": string;
  "Forked Dimension": ForkedDimension;
  "Winner Forked Value": number | string | null | undefined;
};

export class MergeForkEvent<ObjectType extends ObjectTypes> extends TrackingEvent<MergeForkEventData<ObjectType>> {
  readonly type = "[Fork] Merge";
}

export class CreateExperimentGroupEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  SaveExperimentGroupData<ObjectType>
> {
  readonly type = "[Object] Create Experiment Group";
}

export type SaveExperimentGroupData<ObjectType extends ObjectTypes> = ShowScreenEventData<ObjectType> & {
  "Action Name": string;
  "Group Name": string;
  "Group Campaign Ids": string[];
};

export class SaveExperimentGroupEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  SaveExperimentGroupData<ObjectType>
> {
  readonly type = "[Object] Save Experiment Group";
}

export class CreateCreditInvoiceEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[Invoice] Create Credit Invoice";
}

export class ShareAudienceEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { newOrganizationId: string; marketingStrategies: MarketingStrategy[] }
> {
  readonly type = "[Audience] Share";
}

interface CreateImpactReportData {
  "Experiment Id": string;
  "Event Ids": string[];
  "Intervention Ids": string[];

  [id: string]: string[] | number | string;
}

export class CreateImpactReportEvent extends TrackingEvent<CreateImpactReportData> {
  readonly type = "[Impact Report] Create Impact Report";
}
export class CopyReportSummaryToClipboardEvent extends TrackingEvent<CreateImpactReportData> {
  readonly type = "[Impact Report] Copy Report Summary To Clipboard";
}
export class DownloadImpactReportEvent extends TrackingEvent<CreateImpactReportData> {
  readonly type = "[Impact Report] Download Impact Report";
}

export class CopyInvoiceEvent<ObjectType extends ObjectTypes> extends TrackingEvent<ShowScreenEventData<ObjectType>> {
  readonly type = "[Invoice] Copy";
}

export class InvoiceStateChangeEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType> & { newState: InvoiceStateValue }
> {
  readonly type = "[Invoice] State Change";
}

export type LoadSecondaryResourcesEventData<ObjectType extends ObjectTypes> = AnyScreenEventData<ObjectType> & LoadData;

export class LoadSecondaryResourcesEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  LoadSecondaryResourcesEventData<ObjectType>
> {
  readonly type = "[Secondary Resources] Load";
}

export class CopyPredictionFlightEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[Prediction Flight] Copy";
}

export class CopyGlobalExperimentEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[GlobalExperiment] Copy";
}

export class PivotLinkClickEvent<ObjectType extends ObjectTypes> extends TrackingEvent<AnyScreenEventData<ObjectType>> {
  readonly type = "[Pivot Link] Click";
}

export class BudgetPlansCsvEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  BudgetPlansCsvAction<ObjectType>
> {
  readonly type = `[${this.data["Plan Type"]}] Csv ${this.data["Action Name"]}`;
}

type BudgetPlansCsvReport = {
  "Action Name": "Upload" | "Export";
  "Plan Type": string;
};

export type BudgetPlansCsvAction<ObjectType extends ObjectTypes> = ShowScreenEventData<ObjectType> &
  BudgetPlansCsvReport;

export class BudgetPlanPeriodDeleteEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[Daily Plan] Period Delete";
}

export class BudgetPlanPeriodCreateEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  ShowScreenEventData<ObjectType>
> {
  readonly type = "[Daily Plan] Period Create";
}

export type BudgetPlansFormsActions = {
  "Plan Type": string;
  "Field Name": string;
};

type BudgetPlansFormEventType<ObjectType extends ObjectTypes> = ShowScreenEventData<ObjectType> &
  BudgetPlansFormsActions;

export class BudgetPlansFormEvent<ObjectType extends ObjectTypes> extends TrackingEvent<
  BudgetPlansFormEventType<ObjectType>
> {
  readonly type = `[${this.data["Plan Type"]}] Form Field Change`;
}

export class CsvFileEvent<EnclosingObjectType extends ObjectTypes> extends TrackingEvent<
  (IndexScreenEventData<EnclosingObjectType> | ShowScreenEventData<EnclosingObjectType>) & { "Action Name": string }
> {
  readonly type = "[Csv File] Event";
}

type CommandPaletteEventData = {
  Action: "open";
};

export class CommandPaletteOpenEvent extends TrackingEvent<CommandPaletteEventData> {
  readonly type = "[Command Palette] Open";
}

type CommandPaletteSelectData = {
  "Result Type": string;
};

export class CommandPaletteSelectEvent extends TrackingEvent<CommandPaletteSelectData> {
  readonly type = "[Command Palette] Search Result Selected";
}

export class ResetSpendRateEvent extends TrackingEvent<ShowScreenEventData<"budget">> {
  readonly type = "[Spend Rate] Reset";
}

type SimpleDataValueType = string | number | boolean | Date | undefined | null;
type DataValueType = SimpleDataValueType | SimpleDataValueType[];
type FlatDataStructure = { [Key: string]: DataValueType };
type NestedDataStructure = {
  [Key: string]: DataValueType | NestedDataStructure;
};

export const StatusToVerb: { [Key in Status]: string } = {
  active: "Activate",
  paused: "Pause",
  archived: "Archive",
};

export function flattenData(data: FlatDataStructure | NestedDataStructure, prefix?: string): FlatDataStructure {
  const result = {};
  Object.keys(data).forEach(key => {
    const value = data[key];
    const prefixedKey = compact([prefix, key]).join(" ");
    if (value !== null && typeof value === "object" && !(value instanceof Array || value instanceof Date)) {
      const valueWithPrefixedKeys = flattenData(value, prefixedKey);
      Object.assign(result, valueWithPrefixedKeys);
    } else {
      // @ts-expect-error
      result[prefixedKey] = value;
    }
  });
  return result;
}

export interface ITrackingService {
  identify(userId: string, userName: string): void;
  logout(): void;
  setUserInfo(employee: EmployeeInfo): void;
  track(event: TrackingEvent<NestedDataStructure>): void;
}

export interface TrackingServiceAdapter {
  identify(userId: string, userName: string): void;
  logout(): void;
  setUserInfo(employee: EmployeeInfo): void;
  track(eventType: string, eventData: object): void;
}

export default class TrackingService implements ITrackingService {
  constructor(adapter: TrackingServiceAdapter) {
    this.adapter = adapter;
  }

  private adapter: TrackingServiceAdapter;

  identify(userId: string, userName: string) {
    this.adapter.identify(userId, userName);
  }

  logout() {
    this.adapter.logout();
  }

  setUserInfo(employee: EmployeeInfo) {
    this.adapter.setUserInfo(employee);
  }

  track(event: TrackingEvent<NestedDataStructure>): void {
    this.adapter.track(event.type, flattenData(event.data));
  }
}

export class ProductionTrackingServiceAdapter {
  constructor(accessToken: string = import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN || "") {
    mixpanel.init(accessToken, { track_pageview: false });
  }

  identify(userId: string, userName: string) {
    mixpanel.identify(userId);
    mixpanel.people.set({
      $name: userName,
    });
  }

  logout() {
    mixpanel.reset();
  }

  setUserInfo(employee: EmployeeInfo) {
    mixpanel.people.set({
      employee_region: employee.employee_region,
      employee_type: employee.employee_type,
    });
  }

  track(eventType: string, eventData: object) {
    mixpanel.track(eventType, eventData);
  }
}

export class NullTrackingServiceAdapter implements TrackingServiceAdapter {
  identify(userId: string, userName: string) {
    console.log("identify:", { id: userId, Name: userName });
  }

  logout() {
    console.log("logout");
  }

  setUserInfo(employee: EmployeeInfo) {
    console.log("setUserInfo:", {
      employee_region: employee.employee_region,
      employee_type: employee.employee_type,
    });
  }

  track(eventType: string, eventData: object) {
    console.log("track:", eventType, eventData);
  }
}
